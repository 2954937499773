import { createPermissionChecker, prepareIotRoutes } from '../../helper';

const lockBaseRoutes = [
    {
        name: 'iot.locks.all-access-history',
        path: '/iot/locks/access-history',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/AccessHistory.vue'),
        meta: {
            breadcrumbName: 'access history',
            title: 'access history',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('ACCESS_LOG_READ_ALL'),
        },
    },
    {
        name: 'iot.locks.columns',
        path: '/iot/locks/columns',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/LockListColumns.vue'),
        meta: {
            breadcrumbName: 'configure columns',
            title: 'configure columns',
            actions: true,
            flowStartsWith: 'iot.locks.columnss',
            permissionChecker: createPermissionChecker('LOCK_READ_LIST'),
        },
    },
];

export default prepareIotRoutes([...lockBaseRoutes]);
