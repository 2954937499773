import { createPermissionChecker } from '@/router/iot/helper';

export default [
    {
        routeName: 'iot.residents.index',
        items: [
            {
                title: 'create resident',
                routeName: 'iot.residents.create',
                allowed: createPermissionChecker('RESIDENT_CREATE'),
            },
        ],
    },
    {
        routeName: 'iot.residents.details',
        items: [
            {
                title: 'authorizations',
                routeName: 'iot.residents.details.authorizations',
                params: {
                    id: '{id}',
                },
                allowed: createPermissionChecker('RESIDENT_READ'),
            },
            {
                title: 'keyring',
                routeName: 'iot.residents.details.keyring',
                params: {
                    id: '{id}',
                },
                allowed: createPermissionChecker('RESIDENT_READ'),
            },
            {
                title: 'change email',
                routeName: 'iot.residents.change_email',
                params: {
                    id: '{id}',
                },
                allowed: createPermissionChecker('USER_CHANGE_EMAIL'),
            },
            {
                id: 'iot-resident-details-delete-resident',
                title: 'delete resident',
                showConfirmation: true,
                confirmationMessage: 'are you sure you want to delete resident?',
                confirmBtnText: 'yes, delete',
                cancelBtnText: 'no, go back',
                confirmationType: 'warning',
                allowed: createPermissionChecker('USER_DELETE_FROM_COMMUNITY'),
            },
        ],
    },
    {
        routeName: 'iot.residents.details.authorizations',
        items: [
            {
                title: 'add location',
                routeName: 'iot.residents.details.authorizations.add-location',
            },
        ],
        allowed: createPermissionChecker('RESIDENT_READ'),
    },
    {
        routeName: 'iot.residents.details.keyring',
        items: [
            {
                title: 'key management',
                routeName: 'iot.residents.details.keyring.management',
                params: {
                    id: '{id}',
                },
            },
        ],
    },
];
