import store from '@/store';

const permissionChecker = () => store.getters['dh/hasPermission'] || store.getters['cms/hasPermission'];

const routes = [
    {
        name: 'medias.index',
        path: '/media-library/',
        component: () => {
            return import('@/views/media_gallery/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'media gallery',
            title: 'media gallery',
            size: 'large',
            flowStartsWith: 'medias.index',
            product: 'media-gallery',
            permissionChecker,
        },
    },
    {
        name: 'medias.show',
        path: '/media-library/file/:id',
        component: () => {
            return import('@/views/media_gallery/Show');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'view media',
            title: 'view media',
            size: 'large',
            flowStartsWith: 'medias.index',
            product: 'media-gallery',
            requiresCustomer: true,
            requiresCommunity: false,
            permissionChecker,
        },
    },
    {
        name: 'medias.edit',
        path: '/media-library/file/:id/edit',
        component: () => {
            return import('@/views/media_gallery/Edit');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit media',
            title: 'media gallery',
            size: 'large',
            flowStartsWith: 'medias.index',
            product: 'media-gallery',
            requiresCustomer: true,
            requiresCommunity: false,
            permissionChecker,
        },
    },
    {
        name: 'medias.upload',
        path: '/media-library/file/:id/upload',
        component: () => {
            return import('@/views/media_gallery/Upload');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'upload',
            title: 'media gallery',
            size: 'large',
            flowStartsWith: 'medias.index',
            product: 'media-gallery',
            requiresCustomer: true,
            requiresCommunity: false,
            permissionChecker,
        },
    },
    {
        name: 'medias.floorplans.show',
        path: '/media-library/floorplans/:floorplanName/:caller',
        component: () => {
            return import('@/views/cms/floorplans/Show');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'view floor plan',
            title: 'view floor plan',
            flowStartsWith: 'medias.index',
            passFlowTo: 'medias.index',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
        },
    },
];

export default routes;
