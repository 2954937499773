<template>
    <div
        ref="tooltipWrapper"
        class="tooltip"
        @mouseenter="show"
        @mouseleave="hide"
    >
        <icon
            v-if="icon && iconPosition === 'leading'"
            v-bind="iconProps"
        />

        <!-- @slot Label block -->
        <slot name="label">
            {{ label }}
        </slot>

        <icon
            v-if="icon && iconPosition === 'trailing'"
            v-bind="iconProps"
        />

        <PopoverTeleport
            v-if="visible"
            :position-x="positionX"
            :position-y="positionY"
            @mouseenter="show"
            @mouseleave="hide"
        >
            <div
                class="tooltip__popup"
                :class="css.tooltipPopup"
            >
                <!-- @slot Tooltip content -->
                <slot name="default">
                    {{ text }}
                </slot>
            </div>
        </PopoverTeleport>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import PopoverTeleport, { PopoverHorizontalPosition, PopoverVerticalPosition } from '@/components/ui/PopoverTeleport.vue';

const css = {
    tooltipPopup: 'tooltip__popup--default',
};

export default {
    components: {
        PopoverTeleport,
        Icon,
    },

    props: {
        /**
         * Label icon
         */
        icon: {
            type: String,
            required: false,
            default: undefined,
        },

        /**
         * Icon position
         */
        iconPosition: {
            type: String,
            default: 'leading',
            validator(value) {
                return ['leading', 'trailing'].includes(value);
            },
        },

        /**
         * Label text
         */
        label: {
            type: String,
            required: false,
            default: undefined,
        },

        /**
         * Tooltip content text
         */
        text: {
            type: String,
            required: false,
            default: undefined,
        },

        /**
         * Show tooltip only on content overflow
         */
        showOnOverflow: {
            type: Boolean,
            required: false,
            default: false,
        },

        /**
         * Hint's horizontal position
         */
        positionX: {
            type: String,
            default: PopoverHorizontalPosition.AUTO,
            validator(value) {
                return Object.values(PopoverHorizontalPosition).includes(value);
            },
        },

        /**
         * Hint's vertical position
         */
        positionY: {
            type: String,
            default: PopoverVerticalPosition.AUTO,
            validator(value) {
                return Object.values(PopoverVerticalPosition).includes(value);
            },
        },

        /**
         * Custom CSS styles
         */
        css: {
            type: Object,
            default() {
                return css;
            },
        },

        iconClasses: {
            type: String,
            required: false,
            default: '',
        },

        shouldPrevent: {
            type: Function,
            required: false,
            default: undefined,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            visible: false,
            hidingTimer: null,
        };
    },

    computed: {
        hasTooltipContent() {
            return this.text != null || !!this.$slots.default;
        },

        hasLabel() {
            return !!this.$slots['label'] || this.label;
        },

        iconProps() {
            return {
                name: this.icon,
                class: [
                    'tooltip__icon',
                    this.iconPosition === 'leading' && this.hasLabel ? 'tooltip__icon--leading' : '',
                    this.iconPosition === 'trailing' && this.hasLabel ? 'tooltip__icon--trailing' : '',
                    this.iconClasses,
                ],
            };
        },
    },

    methods: {
        show() {
            clearTimeout(this.hidingTimer);
            this.hidingTimer = null;

            if (this.hasTooltipContent && (!this.showOnOverflow || this.hasOverflow()) && !this.shouldPrevent?.() && !this.disabled) {
                this.visible = true;
            }
        },

        hide(e) {
            if (e.relatedTarget !== this.$refs.tooltip) {
                this.hidingTimer = setTimeout(() => {
                    this.visible = false;
                }, 100);
            }
        },

        hasOverflow() {
            const { tooltipWrapper } = this.$refs;
            return tooltipWrapper.scrollWidth > tooltipWrapper.clientWidth || tooltipWrapper.scrollHeight > tooltipWrapper.clientHeight;
        },
    },
};
</script>

<style scoped>
.tooltip {
    @apply inline-flex items-center relative;

    &__icon {
        @apply inline-block w-4 h-4;

        flex: 1 0 auto;

        &--leading {
            @apply mr-1;
        }

        &--trailing {
            @apply ml-1;
        }
    }

    &__popup {
        @apply text-black border border-gray-300 text-2sm p-4 bg-white my-1;

        &--max-height {
            overflow-y: auto;
            max-height: calc(50vh - 4rem);
        }

        &--default {
            @extend .tooltip__popup--max-height;
            max-width: 16rem;
        }
    }
}
</style>
