const routes = [
    {
        name: 'assistant_role_management.index',
        path: '/assistant-role-management',
        component: () => {
            return import('@/views/digital_human_ai/assistant_role_management/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'manage assistant roles',
            title: 'AI assistants',
            size: 'normal',
            flowStartsWith: 'assistant_role_management.index',
            // allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            requiredAppAccess: ['DHAI'],
            product: 'dh',
        },
    },
];

export default routes;
