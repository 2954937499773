export const DELAY_BETWEEN_RESYNC = 30000;
export const LOCK_TABLE_ID = 'iot.locks';

export const LOCK_VENDORS = {
    PDK: 'PDK',
    YALE: 'YALE',
    ALLEGION: 'ALLEGION',
    BRIVO: 'BRIVO',
    BMX: 'BUTTERFLYMX',
};

export const YALE_STATUSES = {
    INSTALLED: 'INSTALLED',
    INSTALLING: 'INSTALLING',
    CONFIGURING: 'CONFIGURING',
    CONFIGURATION_ERROR: 'CONFIGURATION_ERROR',
    UNINSTALLING: 'UNINSTALLING',
    INSTALLATION_ERROR: 'INSTALLATION_ERROR',
    UNINSTALLATION_ERROR: 'UNINSTALLATION_ERROR',
};

export const CONNECTION_STATUSES = {
    ONLINE: 'ONLINE',
    OFFLINE: 'OFFLINE',
};

export const ORIGIN_TYPES = {
    GUEST_PASS: 'Guest Pass',
    RESIDENT_PASS: 'Resident Pass',
    VENDOR_PASS: 'Vendor Pass',
    QUEXT_PASS: 'Quext Pass',
    REMOTE_KEY: 'Remote Key',
    PIN: 'PIN',
    DOOR_RELEASE: 'Door Release',
};

export const INSTALL_MODE = {
    THERMOSTAT: 'THERMOSTAT',
    LOCK_ONLY: 'LOCK_ONLY',
    THIRD_PARTY: 'THIRD_PARTY',
    ETHERNET: 'ETHERNET',
};

export const COMMISSION_METHOD = {
    LORA: 'LORA',
    MOBILE_APP: 'MOBILE_APP',
};

export const LOCK_PACS_QUERY_STATUSES = {
    WAITING_FOR_SYNC: 'WAITING_FOR_SYNC',
    FAILED: 'FAILED',
    UPLOADING: 'UPLOADING',
};

export const BMX_INTERCOM_STATUSES = {
    UNINSTALLED: 'UNINSTALLED',
    INSTALLED: 'INSTALLED',
};
