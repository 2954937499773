import { useLocksStore } from '@/stores/iot/locks';
import { createPermissionChecker } from '@/router/iot/helper';

const locksIsAvailableAction = permission => useLocksStore().isAvailableAction(permission);

const allegionNavigations = [
    {
        routeName: 'iot.locks.allegion',
        items: [
            {
                title: 'lock history',
                routeName: 'iot.locks.allegion.lock-history',
                params: {
                    lockId: '{lockId}',
                },
                allowed: createPermissionChecker('LOCK_READ_ACCESS_LOGS'),
            },
            {
                title: 'connection & audit logs',
                routeName: 'iot.locks.allegion.logs',
                params: {
                    id: '{lockId}',
                },
                allowed: createPermissionChecker(['LOCK_READ_AUDIT_LOG_LIST', 'LOCK_READ_CONNECTION_LOG_LIST']),
            },
            {
                title: 'change location',
                routeName: 'iot.locks.allegion.change-location',
                params: {
                    lockId: '{lockId}',
                },
                allowed: () => createPermissionChecker('LOCK_ASSIGN_TO_LOCATION')() && locksIsAvailableAction('CHANGE_LOCATION'),
            },
            {
                id: 'allegion-lock-change-router',
                title: 'change router',
                routeName: 'iot.locks.allegion.change-router',
                params: {
                    lockId: '{lockId}',
                },
                allowed: () => createPermissionChecker('LOCK_CHANGE_ROUTER')() && locksIsAvailableAction('CHANGE_ROUTER'),
            },
            {
                title: 'lock configuration',
                routeName: 'iot.locks.allegion.lock-configuration',
                params: {
                    lockId: '{lockId}',
                },
                allowed: createPermissionChecker('LOCK_UPDATE_CONFIGURATION'),
            },
            {
                id: 'allegion-lock-resync-db',
                title: 'resync lock db',
                isRegular: true,
                dynamic: true,
                allowed: () => createPermissionChecker('LOCK_RE_SYNC_DB')() && locksIsAvailableAction('RE_SYNC_DATABASE'),
            },
            {
                id: 'allegion-lock-send-security-token',
                title: 'send security token',
                isRegular: true,
                dynamic: true,
                allowed: () => createPermissionChecker('LOCK_FORCE_SECURITY_TOKEN')() && locksIsAvailableAction('SEND_SECURITY_TOKEN'),
            },
            {
                id: 'allegion-lock-sync-time',
                title: 'sync time',
                isRegular: true,
                dynamic: true,
                allowed: () => createPermissionChecker('LOCK_SYNC_TIME')() && locksIsAvailableAction('SYNC_TIME'),
            },
            {
                id: 'allegion-lock-delete',
                title: 'delete',
                isRegular: true,
                dynamic: true,
                allowed: () => createPermissionChecker('LOCK_RETURN_TO_AVAILABLE')() && locksIsAvailableAction('DELETE_LOCK'),
            },
        ],
    },
    {
        routeName: 'iot.locks.allegion.lock-configuration',
        items: [
            {
                id: 'allegion-lock-read-config',
                title: 'read config',
                isRegular: true,
                allowed: () => createPermissionChecker('LOCK_REQUEST_READ_CONFIGURATION')() && locksIsAvailableAction('READ_CONFIG'),
            },
        ],
    },
    {
        routeName: 'iot.locks.allegion.audit-log',
        items: [
            {
                id: 'allegion-lock-send-scan',
                title: 'send scan',
                isRegular: true,
                allowed: () => locksIsAvailableAction('SEND_SCAN_CONNECTION_LOGS'),
            },
            {
                id: 'allegion-lock-resync-db',
                title: 'resync lock db',
                isRegular: true,
                dynamic: true,
                allowed: () => createPermissionChecker('LOCK_RE_SYNC_DB')() && locksIsAvailableAction('RE_SYNC_DATABASE'),
            },
            {
                id: 'allegion-lock-send-security-token',
                title: 'send security token',
                isRegular: true,
                dynamic: true,
                allowed: () => createPermissionChecker('LOCK_FORCE_SECURITY_TOKEN')() && locksIsAvailableAction('SEND_SECURITY_TOKEN'),
            },
            {
                id: 'allegion-lock-sync-time',
                title: 'sync time',
                isRegular: true,
                dynamic: true,
                allowed: () => createPermissionChecker('LOCK_SYNC_TIME')() && locksIsAvailableAction('SYNC_TIME'),
            },
        ],
    },
    {
        routeName: 'iot.locks.allegion.connection-log',
        items: [
            {
                id: 'allegion-lock-send-scan',
                title: 'send scan',
                isRegular: true,
                allowed: () => locksIsAvailableAction('SEND_SCAN_CONNECTION_LOGS'),
            },
            {
                id: 'allegion-lock-resync-db',
                title: 'resync lock db',
                isRegular: true,
                dynamic: true,
                allowed: () => createPermissionChecker('LOCK_RE_SYNC_DB')() && locksIsAvailableAction('RE_SYNC_DATABASE'),
            },
            {
                id: 'allegion-lock-send-security-token',
                title: 'send security token',
                isRegular: true,
                dynamic: true,
                allowed: () => createPermissionChecker('LOCK_FORCE_SECURITY_TOKEN')() && locksIsAvailableAction('SEND_SECURITY_TOKEN'),
            },
            {
                id: 'allegion-lock-sync-time',
                title: 'sync time',
                isRegular: true,
                dynamic: true,
                allowed: () => createPermissionChecker('LOCK_SYNC_TIME')() && locksIsAvailableAction('SYNC_TIME'),
            },
        ],
    },
];

const pdkNavigations = [
    {
        routeName: 'iot.locks.pdk',
        items: [
            {
                title: 'lock history',
                routeName: 'iot.locks.pdk.lock-history',
                params: {
                    lockId: '{lockId}',
                },
                allowed: createPermissionChecker('PDK_AUDIT_LOGS_READ_LIST'),
            },
            {
                title: 'change location',
                routeName: 'iot.locks.pdk.change-location',
                params: {
                    lockId: '{lockId}',
                },
                allowed: createPermissionChecker('PDK_DOOR_CHANGE_LOCATION'),
            },
            {
                title: 'hardware controller',
                routeName: 'iot.locks.pdk.hardware-controller',
                params: {
                    lockId: '{lockId}',
                },
                allowed: createPermissionChecker('PDK_DOOR_READ_HARDWARE'),
            },
            {
                title: 'primary reader configuration',
                routeName: 'iot.locks.pdk.primary-reader-configuration',
                params: {
                    lockId: '{lockId}',
                },
                allowed: createPermissionChecker('PDK_DOOR_READ_CONFIG'),
            },
            {
                title: 'secondary reader configuration',
                routeName: 'iot.locks.pdk.secondary-reader-configuration',
                params: {
                    lockId: '{lockId}',
                },
                allowed: () => {
                    const locksStore = useLocksStore();
                    return createPermissionChecker('PDK_DOOR_READ_CONFIG')() && locksStore.isSecondaryReaderAvailable;
                },
            },
            {
                id: 'pdk-lock-delete',
                title: 'delete',
                showConfirmation: true,
                confirmationMessage: 'Are you sure you want to delete lock?',
                confirmBtnText: 'yes, delete',
                cancelBtnText: 'no, go back',
                confirmationType: 'warning',
                allowed: createPermissionChecker('PDK_DOOR_DELETE'),
            },
        ],
    },
    {
        routeName: 'iot.locks.pdk.lock-history',
        items: [
            {
                id: 'pdk-lock-sync-audits',
                title: 'sync audits',
                isRegular: true,
                allowed: createPermissionChecker('PDK_AUDIT_LOGS_SYNC'),
            },
        ],
    },
    {
        routeName: 'iot.locks.lock-management.pdk',
        items: [
            {
                title: 'import nodes',
                routeName: 'iot.locks.lock-management.pdk.import-nodes',
                allowed: createPermissionChecker('PDK_CLOUD_NODE_READ_ALL'),
            },
        ],
    },
];

const yaleNavigations = [
    {
        routeName: 'iot.locks.yale',
        items: [
            {
                title: 'access history',
                routeName: 'iot.locks.yale.access-history',
                allowed: createPermissionChecker('ACCESS_LOG_READ_ALL'),
            },
            {
                title: 'notification log',
                routeName: 'iot.locks.yale.notification-log',
                params: {
                    lockId: '{lockId}',
                },
                allowed: createPermissionChecker('LOCK_READ_LIST'),
            },
            {
                title: 'lock configuration',
                routeName: 'iot.locks.yale.lock-configuration',
                params: {
                    lockId: '{lockId}',
                },
                allowed: createPermissionChecker('LOCK_UPDATE_CONFIGURATION'),
            },
            {
                title: 'connection log',
                routeName: 'iot.locks.yale.connection-log',
                params: {
                    lockId: '{lockId}',
                },
                allowed: createPermissionChecker('YALE_LOCK_READ_CONNECTION_LOG_LIST'),
            },
            {
                id: 'yale-lock-resync-db',
                title: 'resync lock db',
                isRegular: true,
                allowed: createPermissionChecker('LOCK_RE_SYNC_DB'),
            },
        ],
    },
    {
        routeName: 'iot.locks.yale.notification-log',
        items: [
            {
                title: 'request audits',
                routeName: 'iot.locks.yale.notification-log.request-audits',
                params: {
                    lockId: '{lockId}',
                },
                allowed: createPermissionChecker('LOCK_REQUEST_AUDIT_LOGS'),
            },
        ],
    },
];

const brivoNavigations = [
    {
        routeName: 'iot.locks.brivo',
        items: [
            {
                title: 'lock history',
                routeName: 'iot.locks.brivo.lock-history',
                allowed: createPermissionChecker('BRIVO_DEVICES_READ_DETAILS'),
            },
            {
                title: 'change location',
                routeName: 'iot.locks.brivo.change-location',
                allowed: createPermissionChecker('BRIVO_DEVICES_CHANGE_LOCATION'),
            },
            {
                title: 'site settings',
                routeName: 'iot.settings.access_and_devices.physical',
                allowed: createPermissionChecker('BRIVO_SITES_READ_LIST'),
            },
        ],
    },
];

const locks = [
    {
        routeName: 'iot.locks.index',
        items: [
            {
                title: 'access history',
                routeName: 'iot.locks.all-access-history',
                allowed: createPermissionChecker('ACCESS_LOG_READ_ALL'),
            },
            {
                title: 'configure columns',
                routeName: 'iot.locks.columns',
                permissionChecker: createPermissionChecker('LOCK_READ_LIST'),
            },
        ],
    },
];
export default [...allegionNavigations, ...pdkNavigations, ...yaleNavigations, ...brivoNavigations, ...locks];
